<template>
  <UiContainer tag="ul" class="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6 md:flex">
    <li
      v-for="(link, index) in links"
      :key="link.title"
      :class="{ 'col-span-2 sm:col-span-1': index === 0 }"
    >
      <UiButton
        :tag="link.external ? 'a' : 'RouterLink'"
        severity="secondary"
        :to="link.to"
        :href="link.to"
        class="relative flex w-full justify-between overflow-hidden !p-3 sm:max-w-[266px]"
      >
        <div class="relative">
          <p class="mb-1 font-medium text-text-main">
            {{ link.title }}
          </p>
          <p
            v-if="link.description"
            class="text-sm text-text-secondary"
            v-html="link.description"
          />
        </div>
        <UiIcon
          name="chevron-down"
          class="ml-1 mt-0 size-4 min-w-4 -rotate-90 text-icon-tertiary lg:mt-0.5"
        />
      </UiButton>
    </li>
  </UiContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { UiButton, UiContainer, UiIcon } from '@/6_shared/ui'
import { FAQLinks } from '../config'

const links = computed(() => FAQLinks.filter((_, count) => count < FAQLinks.length - 1))
</script>
